:root { 
--deep_orange_A200:#e56931; 
--light_green_A700:#6dc125; 
--teal_50_7f:#dae6ef7f; 
--red_500:#ff3330; 
--red_A200:#f84d5b; 
--red_900:#9d030e; 
--red_100:#ffd8cc; 
--amber_A700:#ffab09; 
--red_A400:#ff0020; 
--deep_orange_300:#ff6f66; 
--red_A700_01:#f20015; 
--white_A700:#ffffff; 
--red_A700:#d00010; 
--light_blue_500:#12aff0; 
--blue_A200:#6288e9; 
--gray_50:#f9fbfc; 
--black_900_66:#00000066; 
--black_900:#000000; 
--yellow_700:#e6bb30; 
--purple_700:#903878; 
--pink_300:#f47690; 
--pink_400:#e4447f; 
--black_900_26:#00000026; 
--gray_400:#c5c5c5; 
--blue_900:#0730b8; 
--orange_A200:#f5b43f; 
--blue_gray_50_00:#e7eaee00; 
--blue_gray_400:#8c8c8c; 
--blue_gray_300:#809fb8; 
--gray_800:#404040; 
--gray_900:#17181a; 
--light_blue_500_33:#12aff033; 
--teal_50:#cafdee; 
--gray_200:#ebebeb; 
--green_50:#e9ffd7; 
--gray_300:#d9e1e7; 
--blue_50:#e4ecff; 
--gray_100:#f1f4f9; 
--orange_50:#ffe4d5; 
--indigo_200:#99b2c6; 
--gray_100_01:#f5f5f5; 
--indigo_900:#262262; 
--blue_200:#98b5f7; 
--white_A700:#ffffff; 
--cyan_500:#00d0db; 
}